<template>
    <component
        v-bind="$attrs"
        :is="tag"
        :type="type"
        :disabled="loading ? true : undefined"
        class="flex items-center whitespace-nowrap rounded-sm text-sm font-bold text-white"
        :class="{
            [bgClass]: true,
            'hover:bg-orange-500 focus:bg-orange-500': !disabled,
            'cursor-not-allowed': disabled,
            [disabledClass]: disabled,
            'px-1 py-0': size === 'xs',
            'px-2 py-1': size === 'small',
            'px-6 py-3': size === 'default',
        }"
        @click="(e: MouseEvent) => $emit('click', e)"
    >
        <div v-if="loading" class="btn-spinner mr-2" />
        <div>
            <slot />
        </div>
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        type: {
            type: String,
            default: 'button',
        },
        bgClass: {
            type: String,
            default: 'bg-indigo-600',
        },
        disabledClass: {
            type: String,
            default: 'bg-indigo-300',
        },
        size: {
            type: String as PropType<'xs' | 'small' | 'default'>,
            default: 'default',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click'],
});
</script>

<style>
.btn-spinner,
.btn-spinner:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
}

.btn-spinner {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.2em solid white;
    border-right: 0.2em solid white;
    border-bottom: 0.2em solid white;
    border-left: 0.2em solid transparent;
    transform: translateZ(0);
    animation: spinning 1s infinite linear;
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
